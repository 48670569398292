export enum Memory {
  SpellOrientation  = 1,
  SpellTileX        = 2,
  SpellTileY        = 3,
  RealTimeScore     = 4,
  AppliedSpellNorth = 5,
  AppliedSpellEast  = 6,
  AppliedSpellSouth = 7,
  AppliedSpellWest  = 8,
  TilePreview       = 9
}
