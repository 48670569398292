export enum LocationType {
  PlayerHand         = 1,
  PlayerDeck         = 2,
  PlayerGolemStack   = 3,
  Board              = 4,
  // Reserved = 5
  TileButton         = 6,
  PlayerDeckQuantity = 7,
  TileScore          = 8,
  PlayerWizardStart  = 9
}
